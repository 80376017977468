import Axios from '@/http'
export default {
  /* 人脸识别明细 */
  recognitionDetail(params, type) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `recognition/${type || 'white'}/recognitionDetail`,
        params
      ).then((res) => {
        resolve(res)
      })
    })
  }
}
