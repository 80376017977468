/* 路由 */
const routes = [
  {
    path: '*',
    redirect: '/index'
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      label: '报警中心'
    },
    component: () => import('@/views/dataCenter/dataCenter')
  }
]
const componentsContent = {
  /* 指挥调度页面 */
  'map-of-china': 'map-of-china_tiantong',
  quantityPieChart: 'quantityPieChart_tiantong',
  dataCenter: 'dataCenter_tiantong'
}
/* 组件样式 */
const componentsStyle = {}
const assetsUrl = 'src/assets/tiantong'

module.exports = {
  routes,
  assetsUrl,
  componentsContent,
  componentsStyle
}
