import Axios from '@/http'

export default {
  /* 地图设置 */
  mapSettingInfo(params) {
    return new Promise((resolve, reject) => {
      Axios.post('common/mapSettingInfo', params).then((res) => {
        resolve(res)
      })
    })
  },
  businessSystemNameLogo() {
    return new Promise((resolve, reject) => {
      Axios.post('common/businessSystemNameLogo').then((res) => {
        return resolve(res)
      })
    })
  }
}
