// 首页
import Axios from '@/http'
export default {
  //  首页统计
  numCal(controller) {
    return new Promise((resolve, reject) => {
      Axios.post(controller + '/numCal').then((res) => {
        resolve(res)
      })
    })
  },
  // 城市详情
  mapProvinceDetail(controller, params) {
    return new Promise((resolve, reject) => {
      Axios.post(controller + '/mapProvinceDetail', params).then((res) => {
        resolve(res)
      })
    })
  },
  // 地图显示城市
  mapCal(controller) {
    return new Promise((resolve, reject) => {
      Axios.post(controller + '/mapCal').then((res) => {
        resolve(res)
      })
    })
  },
  // 存储预警趋势图
  lineChart(controller, params) {
    return new Promise((resolve, reject) => {
      Axios.post('homePage/' + controller, params).then((res) => {
        resolve(res)
      })
    })
  },

  // 文件数量
  getfileNumCal() {
    return new Promise((resolve) => {
      Axios.post('homePage/fileNumCal').then((res) => resolve(res))
    })
  }
}
