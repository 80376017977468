import Axios from '@/http'

export default {
  getOnlineDev() {
    return new Promise((resolve, reject) => {
      Axios.post('device/onlineDev').then((res) => {
        return resolve(res)
      })
    })
  },
  countNum() {
    // 设备总数/在线数目/离线数目
    return new Promise((resolve, reject) => {
      Axios.post('device/countNum').then((res) => {
        return resolve(res)
      })
    })
  },
  devicesInGroup(params) {
    return new Promise((resolve, reject) => {
      Axios.post('device/devicesInGroup', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  devicesOutGroup(params) {
    return new Promise((resolve, reject) => {
      Axios.post('device/devicesOutGroup', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  update(params) {
    return new Promise((resolve, reject) => {
      Axios.post('device/update', params, { isJSON: true }).then((res) => {
        resolve(res)
      })
    })
  },
  detail(params) {
    return new Promise((resolve, reject) => {
      Axios.post('device/detail', params).then((res) => {
        res.data.onlineStateStr = res.data.onlineState === 1 ? '在线' : '离线'
        resolve(res)
      })
    })
  },
  devAssignOrgBatch(params) {
    return new Promise((resolve, reject) => {
      Axios.post('device/devAssignOrgBatch', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 设备定点搜索 */
  locationNearBy(params) {
    return new Promise((resolve, reject) => {
      Axios.post('device/location/nearBy', params).then((res) => {
        resolve(res)
      })
    })
  },
  findDevByLatLng(params) {
    return new Promise((resolve, reject) => {
      Axios.post('device/findDevByLatLng/findAll', params, {
        isJSON: true
      }).then((res) => {
        resolve(res)
      })
    })
  },
  findAll(params) {
    return new Promise((resolve, reject) => {
      Axios.post('device/findAll', params).then((res) => {
        const result = res.data.map((deviceItem) => {
          if (deviceItem.onlineState === 1) {
            deviceItem.onlineStateStr = '在线'
          } else if (deviceItem.onlineState === 2) {
            deviceItem.onlineStateStr = '离线'
          }
          return deviceItem
        })
        resolve(result)
      })
    })
  },
  activateDev(params) {
    return new Promise((resolve, reject) => {
      Axios.post('device/activateDev', params, { isJSON: true }).then((res) => {
        resolve(res)
      })
    })
  }
}
