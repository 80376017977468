<template>
  <div id="app">
    <router-view v-if="dialogVisible" />
  </div>
</template>

<style lang="less">
.operator-wrapper {
  justify-content: space-between;
  height: 100%;
  .operator-menu-box {
  }
  .operator-content {
    width: 100%;
    height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
<script type="text/ecmascript-6">
  const { userGuidanceList } = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)
  export default {
  data () {
    return {
      userGuidanceList: userGuidanceList,
      dialogVisible: false
    }
  },
  watch: {
    'userGuidance.header': function (v) {
      if (v) {
        this.userGuidanceFn()
      }
    }
  },
  computed: {
    userGuidance () {
      return this.mainStore.state.user.userGuidance
    }
  },
  destroyed() {
    this.dialogVisible = false
  },
  methods: {
    show() {
      if (this.dialogVisible) {
        return
      }
      let userInfo = window.sessionStorage.getItem('userInfo')
      if (userInfo) {
        userInfo = JSON.parse(userInfo)
        this.$store.commit('setUserInfo', userInfo)
        this.dialogVisible = true
      }
    },
    /* 用户引导 */
    userGuidanceFn () {
      if (this.userGuidanceList && this.userGuidance) {
        if (this.userGuidanceList.length && !this.userGuidance.dataCenter && this.userGuidance.header) {
          this.$deleteConfirmationBox(
            {
              deleteTitleImg: 'tips',
              deleteTitle: '功能介绍',
              submitClass: 'button_function',
              deleteContent: '<div class="c_9DA7B2" style="letter-spacing: 2px">是否需要新手指引<span class="c_fff">首页说明</span>？</div>',
              closeText: '跳 过',
              submitText: '确 定'
            },
            () => {
              const userGuidance = this.mainStore.getters.windowFn.userGuidance
              const defineStepsList = []
              const permissionIdList = JSON.parse(window.sessionStorage.getItem('permissionIdList')) || []
              /* 判断是否有权限 */
              const userGuidanceList = this.userGuidanceList.filter((v) => {
                if (v.permission) {
                  return permissionIdList.findIndex(permission => permission === v.permission) !== -1
                } else {
                  return true
                }
              })
              const num = userGuidanceList.length
              /* 整理引导数据 */
              userGuidanceList.forEach((row, index) => {
                const item = {
                  element: row.element,
                  popover: { ...row.popover }
                }
                let li = ''
                for (let i = 0; i < num; i++) {
                  li += i === index ? '<li class="select"></li>' : '<li></li>'
                }
                if (index === 0) {
                  item.popover.className = 'first-step-class'
                  item.onPrevious = () => {
                    userGuidance.preventMove()
                  }
                }
                item.popover.description = `<div class="content">${item.popover.description}</div><ul class="centerBox">${li}</ul>`
                item.onHighlightStarted = () => {
                  if (row.query && row.path) {
                    this.$router.push({ path: row.path, query: row.query })
                  } else {
                    if (row.path) {
                      this.$router.push({ path: row.path })
                    }
                  }
                }
                item.onNext = () => {
                }
                defineStepsList.push(item)
              })
              /* 标记当前引导的板块 */
              this.mainStore.commit('guidanceSectionName', 'dataCenter')
              this.$nextTick(() => {
                // this.defineStepsList = defineStepsList
                userGuidance.defineSteps(defineStepsList)
                userGuidance.start()
              })
            },
            () => {
              this.mainStore.dispatch('userGuidanceFn', {
                name: 'dataCenter',
                content: 1
              })
            }
          )
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const mqObj = this.mainStore.getters.mqObj
      if (mqObj && mqObj.sdkType && mqObj.businessType) {
        this.show()
      }
      this.mainStore.commit('sdkAssignmentCallbackObject', {
        name: 'mqConnectionType',
        callback: (type) => {
          if (type) {
            this.show()
          }
        }
      })
    })
  }
}
</script>
