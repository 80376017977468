import Axios from '@/http'
export default {
  // 关注用户
  getFollowUsers() {
    return new Promise((resolve, reject) => {
      Axios.post('userAttenDev/userAttenDev').then((res) => {
        resolve(res)
      })
    })
  }
}
