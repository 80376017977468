import Axios from '@/http'
export default {
  /* 年考勤总览 */
  yearCal() {
    return new Promise((resolve, reject) => {
      Axios.post('dailyCheck/yearCal').then((res) => {
        resolve(res)
      })
    })
  }
}
